export default {

  LOGIN: "/auth/login",
  CHANGE_PASSWORD: "/auth/pwdchange",
  REFRESH_TOKEN: "/user/refreshToken",
  REGISTRATION: "/user/register",
  VERIFY_REGISTRATION: "/user/validate",
  VALIDATE_OTP: "/validate/otp",
  USER_PROFILE:'/common/req',

// Dashboard
ACCOUNT_DETAILS:"/common/req",
CONFIRM_OTP:"/validate/transotp",
MINI_STATEMENT:"common/req",
VALIDATE_MOB:"/common/req",
LOAN_APP:"/common/req",

SAVE_DYNAMIC_FORM: "/user/saveform",
COMM_REQ: "/common/req",





};
